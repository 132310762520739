import Randomizer from "../../../../src/docs/components/randomizer";
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import Purchase from "../../../../src/docs/components/purchase";
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import Dominion from "../../../../src/docs/assets/dominion.svg";
import * as React from 'react';
export default {
  Randomizer,
  ClientRandomizer,
  Purchase,
  Zoom,
  Dominion,
  React
};