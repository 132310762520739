import Randomizer from "../../../../src/docs/components/randomizer";
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import yuki from "../../../../src/docs/assets/yuki.jpg";
import eckstein from "../../../../src/docs/assets/eckstein.jpg";
import gibbs from "../../../../src/docs/assets/gibbs.jpg";
import martin from "../../../../src/docs/assets/martin.jpg";
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import * as React from 'react';
export default {
  Randomizer,
  ClientRandomizer,
  yuki,
  eckstein,
  gibbs,
  martin,
  Zoom,
  React
};