export default {
  colors: {
    primary: '#5E81AC',
    background: '#2E3440',
    sidebar: {
      background: '#2E3440',
      link: '#999',
      heading: '#aaa',
      linkActive: '#13131A',
      itemActive: '#F5F5FA',
      footer: '#A8A8B3',
    },
  },
};
