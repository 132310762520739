import facebook from "../../../../src/docs/assets/facebook.svg";
import instagram from "../../../../src/docs/assets/instagram.svg";
import twitter from "../../../../src/docs/assets/twitter.svg";
import soundcloud from "../../../../src/docs/assets/soundcloud.svg";
import spotify from "../../../../src/docs/assets/spotify.svg";
import youtube from "../../../../src/docs/assets/youtube.svg";
import * as React from 'react';
export default {
  facebook,
  instagram,
  twitter,
  soundcloud,
  spotify,
  youtube,
  React
};