import React from 'react';
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

function MillsCard(props) {
  return (
    <>
      <Zoom>
        <div className="mills-card">
          <img src={'/profile/' + props.img} alt={props.name} />

          <h2>{props.name}</h2>

          <hr />
          <br />

          <p>
            <em>{props.job}</em>
          </p>

          <hr />
          <br />
        </div>
      </Zoom>
    </>
  );
}

export default MillsCard;
