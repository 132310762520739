import Randomizer from "../../../../src/docs/components/randomizer";
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import tad from "../../../../src/docs/assets/tad-calcara.jpg";
import fanfare from "../../../../src/docs/assets/fanfare.jpg";
import augusta from "../../../../src/docs/assets/augusta.jpg";
import * as React from 'react';
export default {
  Randomizer,
  ClientRandomizer,
  tad,
  fanfare,
  augusta,
  React
};